body,
html {
  font-family: "Arial", sans-serif;
  margin: 0;
  padding: 0;
  height: 100%;
  box-sizing: border-box;
}

.root {
  display: flex;
  flex-direction: column;
  font-size: medium;
}

.App-header {
  background-color: darkblue;
  color: #ffffff;
  padding: 20px;
  text-align: center;
  width: 100%;
}

.NavBar ul {
  background-color: #c60c30;
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  margin: 0;
  height: 3rem;
  align-items: center;
}

.NavBar li {
  margin: 0 15px;
}

.NavBar a {
  color: yellow;
  width: 2rem;
  height: auto;
}

section {
  padding: 20px;
  margin: 20px 0;
}

.main-content {
  flex: 1;
}

footer {
  background-color: #f8f8f8;
  text-align: center;
  padding: 10px;
}

footer ul {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
}

footer li {
  margin: 0 10px;
}

footer a {
  text-decoration: none;
  color: #102643;
}

.title {
  width: 100%;
  text-align: center;
  height: 280px;
}

.center {
  width: 45rem;
}

.wrapper {
  /*This part is important for centering*/
  display: grid;
  place-items: center;
  font-family: monospace;
}

.typing-demo {
  width: 22ch;
  animation: typing 2s steps(22), blink 0.5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid;
  font-family: monospace;
  font-size: 1.5rem;
  color: #ffffff;
}

@keyframes typing {
  from {
    width: 0;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}

.App-header {
  background-color: #02468f;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 4rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 8vh; /* Adjust height as needed */
  width: 100vw;
}

.header-content {
  padding: 2vh;
  border-radius: 10px;
  width: 100vw;
  flex-wrap: wrap;
  color: white;
}

.home-content {
  padding: 2vh;
  border-radius: 10px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  text-align: left;
}

.contentStyle {
  width: 100vw;
}

.footerStyle {
  width: 100%;
  padding: 0;
  margin: 0;
}

.homeWelcomeMessage {
  width: 95vw;
  text-align: center;
  margin-left: 4vw;
}

.lodge-logo {
  width: 20vw; /* Adjust the size as needed */
  height: auto;
  margin-right: 20px; /* Space between the image and the text */
  float: left; /* Ensure the image floats to the left */
}

.text-content {
  flex: 1;
  overflow: hidden; /* Prevent text from overflowing */
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .home-content {
    flex-direction: column; /* Stack image on top of text */
    align-items: left;
  }

  .lodge-logo {
    width: 100%; /* Image takes full width on smaller screens */
    margin-right: 0; /* Remove right margin */
    margin-bottom: 20px; /* Add bottom margin for spacing */
  }

  .text-content {
    width: 100%;
  }

  .homeWelcomeMessage {
    text-align: left;
  }
}
.text-content p {
  margin: 0 0 10px; /* Spacing between paragraphs */
}

.App-header h1 {
  margin: 0;
  color: #ffffff;
  font-size: 2rem;
}
