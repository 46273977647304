.blogContentDiv {
  padding: 0;
  text-align: left;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding-left: 3rem;
  padding-top: 1rem;
  padding-right: 1rem;
  font-size: medium;
  width: 80vw;
}

.textAroundImage {
  float: left;
  font-size: medium;
  width: 50vw;
}

.responsive-image {
  width: 500px; /* Default width: full width of the parent */
  max-width: 500px; /* Max width to limit the size */
  height: auto;
  float: left;
}

@media (max-width: 768px) {
  .blogContentDiv {
    padding-left: 1rem;
  }

  .textAroundImage {
    width: 70vw;
  }

  .responsive-image {
    max-width: 400px; /* Smaller max-width for tablets */
    height: auto;
  }
}

@media (max-width: 480px) {
  .responsive-image {
    max-width: 300px; /* Smaller max-width for mobile devices */
    height: auto;
  }
}
