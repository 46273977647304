.card {
    display: flex;
    align-items: center;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px;
    margin: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 500px; /* Adjust width as needed */
  }
  
  .card-photo {
    width: 100px; /* Adjust size as needed */
    height: 100px; /* Adjust size as needed */
    border-radius: 50%; /* Makes the photo circular */
    margin-right: 20px; /* Space between photo and text */
  }
  
  .card-name {
    flex: 1;
  }
  
  .card-name h2 {
    margin: 0;
    font-size: 1.5em;
    color: #333;
  }
  