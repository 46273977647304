footer {
  padding: 20px;
  background-color: #f1f1f1;
  width: 100%;
  margin: 0;
  border: "1px solid red";
  font-size: medium;
}

.flexFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
}

.footerList {
  padding: 0;
  margin: "1rem";
}

.footerList li {
  margin: 0.3rem;
}

.footer-left {
  display: flex;
  flex-direction: column;
}

.footer-left p {
  margin: 0;
}

.footer-left a {
  margin-right: 10px;
}

.footer-right {
  display: flex;
  align-items: center;
}

.footer-logo {
  height: 11vh; /* Adjust the size as needed */
  padding-right: 2vw;
}

.disclaimerP {
  text-align: left;
}
