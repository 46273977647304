.mobileHidden {
  display: block;
}

.mobileVisible {
  display: none;
}

@media (max-width: 768px) {
  .mobileHidden {
    display: none;
  }
  .mobileVisible {
    display: block;
  }
}
